
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

interface QueryParams {
    code: string;
    name: string;
    status: string;
    email: string;
    phone: string;
    external: boolean | null;
    query: string;
    activity_field: string;
    activity_code: string;
}

export default defineComponent({
    name: "search-supplier-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const statuses = ref([]);
        const activityFields = ref([]);
        const activityCodes = ref([]);

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchSupplierModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            code          : "",
            name          : "",
            status        : "",
            email         : "",
            phone         : "",
            external      : false,
            query         : "",
            activity_field: "",
            activity_code: "",
        };
        const queryParams = ref<QueryParams>(props.params);
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchSupplierModalRef.value);
        };

        const getStatuses = async () => {
            const response = await axios.get(ApiRoutes.suppliers.statuses);

            statuses.value = response.data;
        };

        const getActivityFields = async () => {
            const response = await axios.get(ApiRoutes.suppliers.activityFields);

            activityFields.value = response.data;
        };

        const getActivityCodes = async () => {
            const response = await axios.get(ApiRoutes.suppliers.activityCodes);

            activityCodes.value = response.data;
        };

        onMounted(() => {
            getStatuses();
            getActivityFields();
            getActivityCodes();

            const modal = document.getElementById('kt_modal_search_supplier');

            // if (modal) modal.addEventListener('hidden.bs.modal', function() {
            // });

            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.keys(props.params).forEach((key) => {

                        if (initialValues.hasOwnProperty(key)) {
                            if (typeof initialValues[key] == 'boolean') queryParams.value[key] = Boolean(props.params[key]);
                            else queryParams.value[key] = props.params[key];
                        }
                    });
                }
            });
        });

        return {
            translate,
            queryParams,
            statuses,
            activityFields,
            activityCodes,
            submit,
            submitButtonRef,
            searchSupplierModalRef,
        };
    },
});
