
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "edit-supplier-modal",
    emit: ["refreshData"],
    props: {
        supplier: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editSupplierModalRef = ref<null | HTMLElement>(null);

        let isUpdate = false as boolean;
        let errors: any = ref();
        const statuses = ref([]);
        const activityFields = ref([]);
        const activityCodes = ref([]);

        const supplierDefault = {
            id                 : null,
            code               : "",
            name               : "",
            company            : "",
            capital            : 0,
            status             : "",
            email              : "",
            phone              : "",
            external           : false,
            hq                 : false,
            hq_name            : "",
            hq_email           : "",
            hq_phone           : "",
            hq_address         : "",
            activity_field     : "",
            activity_code      : "",
            office_address     : "",
            commercial_address : "",
            commercial_address2: "",
            nif                : "",
            nis                : "",
            nrc                : "",
        };
        const supplierData = ref<any>({});
        Object.assign(supplierData.value, supplierDefault);

        const validationSchema = Yup.object().shape({
            // code: Yup.string().required().label(translate("supplierCodeLabel")),
            // name: Yup.string().required().label(translate("supplierNameLabel")),
            // company: Yup.string().required().label(translate("supplierCompanyLabel")),
            // capital: Yup.number().min(1).required().label(translate("supplierCapitalLabel")),
            // status: Yup.string().required().label(translate("supplierStatusLabel")),
            // email: Yup.string().email().required().label(translate("supplierEmailLabel")),
            // phone: Yup.string().required().label(translate("supplierPhoneLabel")),
            // external: Yup.boolean().label(translate("supplierExternalLabel")),
            // hq: Yup.boolean().label(translate("supplierHqLabel")),
            // hq_name: Yup.string().when('hq', {
            //     is: true,
            //     then: (schema) => schema.required(),
            //     otherwise: (schema) => schema.nullable(),
            // }).label(translate("supplierHqNameLabel")),
            // hq_email: Yup.string().when('hq', {
            //     is: true,
            //     then: (schema) => schema.required(),
            //     otherwise: (schema) => schema.nullable(),
            // }).label(translate("supplierHqEmailLabel")),
            // hq_phone: Yup.string().when('hq', {
            //     is: true,
            //     then: (schema) => schema.required(),
            //     otherwise: (schema) => schema.nullable(),
            // }).label(translate("supplierHqPhoneLabel")),
            // hq_address: Yup.string().when('hq', {
            //     is: true,
            //     then: (schema) => schema.required(),
            //     otherwise: (schema) => schema.nullable(),
            // }).label(translate("supplierHqAddressLabel")),
            // activity_field: Yup.string().required().label(translate("supplierActivityFieldLabel")),
            // activity_code: Yup.string().label(translate("supplierActivityCodeLabel")),
            // office_address: Yup.string().required().label(translate("supplierOfficeAddressLabel")),
            // commercial_address: Yup.string().required().label(translate("supplierCommercialAddressLabel")),
            // commercial_address2: Yup.string().label(translate("supplierCommercialAddress2Label")),
            // nif: Yup.string().required().label(translate("supplierNifLabel")),
            // nis: Yup.string().required().label(translate("supplierNisLabel")),
            // nrc: Yup.string().required().label(translate("supplierNrcLabel")),
        });

        const getStatuses = async () => {
            const response = await axios.get(ApiRoutes.suppliers.statuses);

            statuses.value = response.data;
        };

        const getActivityFields = async () => {
            const response = await axios.get(ApiRoutes.suppliers.activityFields);

            activityFields.value = response.data;
        };

        const getActivityCodes = async () => {
            const response = await axios.get(ApiRoutes.suppliers.activityCodes);

            activityCodes.value = response.data;
        };

        const submit = (values, actions) => {

            errors.value = null;

            disableSubmitButton(submitButtonRef);

            if (isUpdate && props.supplier.id) supplierData.value.id = props.supplier.id;

            axios.post(ApiRoutes.suppliers.updateOrCreate, supplierData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editSupplierModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        onMounted(() => {
            getStatuses();
            getActivityFields();
            getActivityCodes();

            const modal = document.getElementById('kt_modal_edit_supplier');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(supplierData.value, supplierDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;
                errors.value = null;

                if (props.supplier) {
                    isUpdate = true;

                    // Fill supplier fields
                    Object.keys(props.supplier).forEach((key) => {
                        if (supplierDefault.hasOwnProperty(key)) {
                            if (typeof supplierDefault[key] == 'boolean') supplierData.value[key] = Boolean(props.supplier[key]);
                            else supplierData.value[key] = props.supplier[key];
                        }
                    });
                }
            });
        });

        return {
            translate,

            submitButtonRef,
            editSupplierModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            supplierData,
            statuses,
            activityFields,
            activityCodes,

            submit,
        };
    },
});
